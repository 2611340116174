import React from "react";
import ClientSlider from "./ClientSlider";

import sariroti from "../../../images/clientLogos/sariroti.png";
import cocacola from "../../../images/clientLogos/cocacola.png";
import freshtea from "../../../images/clientLogos/freshtea.png";
import bni from "../../../images/clientLogos/bni.png";
import bfi from "../../../images/clientLogos/bfi.png";
import alfamidi from "../../../images/clientLogos/alfamidi.png";
import indomaret from "../../../images/clientLogos/indomaret.png";
import lazada from "../../../images/clientLogos/lazada.png";
import bli from "../../../images/clientLogos/bli.png";
import rucika from "../../../images/clientLogos/rucika.png";
import samuniv from "../../../images/clientLogos/samuniv.png";
import samaca from "../../../images/clientLogos/samaca.png";
import adira from "../../../images/clientLogos/adira.png";
import mansek from "../../../images/clientLogos/mansek.png";
import titiktemu from "../../../images/clientLogos/titiktemu.png";
import joomba from "../../../images/clientLogos/joomba.jpg";

import { BsWhatsapp } from "react-icons/bs";
import { motion } from "framer-motion";

export default function Client() {
  const arrLogo = [
    { logo: sariroti, link: "http://wa.me/6281132214900" },
    { logo: cocacola, link: "" },
    { logo: freshtea, link: "" },
    { logo: bni, link: "" },
    { logo: bfi, link: "" },
    { logo: alfamidi, link: "" },
    { logo: indomaret, link: "" },
    { logo: lazada, link: "" },
    { logo: bli, link: "" },
    { logo: rucika, link: "http://wa.me/628111018601" },
    { logo: samuniv, link: "http://wa.me/6281234368234" },
    { logo: samaca, link: "http://wa.me/6281234367234" },
    { logo: adira, link: "http://wa.me/6281210833803" },
    { logo: mansek, link: "http://wa.me/6281533314032?text=halo-%3E" },
    { logo: titiktemu, link: "", size: 40 },
    { logo: joomba, link: "", size: 40 },
  ];

  const handleWhatsAppMe = (link) => link && window.open(link, "_blank");

  return (
    <section className="client container py-main bg-white position-relative">
      <h2 className="text-center mb-3" data-aos="fade-up" data-aos-offset="0">
        CLIENT
      </h2>
      <p className="text-center mb-0 desc" data-aos="fade-up">
        Empowering businesses to create memorable experiences
      </p>
      <div className="d-flex wraplogos border-bottom overflow-hidden">
        {arrLogo.map((res, i) => (
          <div
            className="figlogo d-flex justify-content-center align-items-center "
            key={i}
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset="0"
          >
            <motion.div
              onClick={() => handleWhatsAppMe(res.link)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="wrapwa pointer"
            >
              <img className="" src={res.logo} alt="logo" />
              {res.link && (
                <div className="wame">
                  <BsWhatsapp color="white" size={18} />
                </div>
              )}
            </motion.div>
          </div>
        ))}
        {[1, 1, 1, 1, 1].map((res, i) => (
          <div key={i} className="figlogo mb-0"></div>
        ))}
      </div>
      <div className="mt-3 mt-md-5">
        <ClientSlider />
      </div>
    </section>
  );
}
